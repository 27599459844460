import _Box from "@mui/material/Box";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Icon, Paragraph, SIcon, Wrapper } from './AccountProgressItem.style';
import { testIds } from './AccountProgressItem.model';
import checked from './assets/checked.svg';
import unchecked from './assets/unchecked.svg';
export const AccountProgressItem = _ref => {
  let {
    completed,
    link,
    subtitle,
    testId,
    title,
    stepsDone,
    appActionCallback
  } = _ref;
  const innerContent = useMemo(() => __jsx(Wrapper, {
    padding: true
  }, __jsx(Wrapper, null, __jsx(Icon, _extends({
    src: unchecked,
    alt: "Not completed"
  }, testIds.iconNotCompleted.attr)), __jsx(_Box, {
    sx: {
      ml: 4
    }
  }, __jsx(Paragraph, _extends({
    sx: {
      fontWeight: 'medium'
    },
    inverse: true
  }, testIds.titleNotCompleted.attr), title), __jsx(Paragraph, _extends({
    inverse: true
  }, testIds.subtitleNotCompleted.attr), subtitle))), __jsx(SIcon, null, __jsx(ChevronRightIcon, null))), [subtitle, title]);
  if (completed) {
    return __jsx(Wrapper, {
      padding: true,
      hasBg: true,
      "data-cy": testId
    }, __jsx(Wrapper, null, __jsx(Icon, _extends({
      src: checked,
      alt: "Completed"
    }, testIds.iconCompleted.attr)), __jsx(_Box, {
      sx: {
        ml: 4
      }
    }, __jsx(Paragraph, _extends({
      sx: {
        fontWeight: 'medium'
      },
      inverse: true
    }, testIds.titleCompleted.attr), title))));
  }
  return __jsx(Wrapper, {
    hasHover: true,
    hasBg: true,
    "data-cy": testId
  }, !stepsDone.READY && appActionCallback ? __jsx(_Box, _extends({
    onClick: appActionCallback
  }, testIds.linkNotCompleted.attr), innerContent) : __jsx(Link, _extends({
    to: link || ''
  }, testIds.linkNotCompleted.attr), innerContent));
};