var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { InvestorEntityStatus, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { useLatestIncompleteInvestorEntity, useInvestorEntityAppActions } from '@yieldstreet/tool-kit';
import { AccountProgress } from './AccountProgress';
export const AccountProgressContainer = () => {
  const {
    investorEntity: incompleteAccount,
    investorEntityStatus: incompleteAccountStatus
  } = useLatestIncompleteInvestorEntity();
  const accountAction = useInvestorEntityAppActions({
    investorEntity: incompleteAccount,
    investorEntityStatus: incompleteAccountStatus,
    managementStrategy: PortfolioStrategyValues.Self
  });
  const stepsDone = useMemo(() => {
    var _incompleteAccountSta;
    return {
      ACCOUNT: !!incompleteAccount,
      IDENTITY: (incompleteAccountStatus === null || incompleteAccountStatus === void 0 ? void 0 : (_incompleteAccountSta = incompleteAccountStatus.identity) === null || _incompleteAccountSta === void 0 ? void 0 : _incompleteAccountSta.status) === InvestorEntityStatus.CONFIRMED,
      READY: false
    };
  }, [incompleteAccount, incompleteAccountStatus]);

  // will be ready if all the other steps are completed
  stepsDone.READY = stepsDone.ACCOUNT && stepsDone.IDENTITY;
  const action = useMemo(() => {
    if (!incompleteAccount) {
      return {
        title: 'Get started',
        link: '/create-investor-account'
      };
    }
    return accountAction;
  }, [incompleteAccount, accountAction]);
  return __jsx(AccountProgress, {
    actionLink: action === null || action === void 0 ? void 0 : action.link,
    appActionCallback: action === null || action === void 0 ? void 0 : action.action,
    stepsDone: stepsDone
  });
};